<template>
    <v-app>
      <div class="row g-5 g-xl-10 mb-5 mb-xl-10">
        <!--begin::Col-->
        <div class="col-xl-8">

          <!--begin::Table widget 8-->
          <div class="card h-xl-100">
            <!--begin::Header-->
            <div class="card-header position-relative py-0 border-bottom-2">
              <!--begin::Nav-->
              <ul class="nav nav-stretch nav-pills nav-pills-custom d-flex mt-3" role="tablist">
                <!--begin::Nav item-->
                <li class="nav-item p-0 ms-0 me-8" role="presentation">
                  <!--begin::Nav link-->
                  <a class="nav-link btn btn-color-muted px-0 active" data-bs-toggle="tab" href="#kt_table_widget_7_tab_content_1" aria-selected="true" role="tab">
                    <!--begin::Title-->
                    <span class="nav-text fw-semibold fs-4 mb-3">
                            Monday                        </span>
                    <!--end::Title-->

                    <!--begin::Bullet-->
                    <span class="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded"></span>
                    <!--end::Bullet-->
                  </a>
                  <!--end::Nav link-->
                </li>
                <!--end::Nav item-->
                <!--begin::Nav item-->
                <li class="nav-item p-0 ms-0 me-8" role="presentation">
                  <!--begin::Nav link-->
                  <a class="nav-link btn btn-color-muted px-0" data-bs-toggle="tab" href="#kt_table_widget_7_tab_content_2" aria-selected="false" role="tab" tabindex="-1">
                    <!--begin::Title-->
                    <span class="nav-text fw-semibold fs-4 mb-3">
                            Tuesday                        </span>
                    <!--end::Title-->

                    <!--begin::Bullet-->
                    <span class="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded"></span>
                    <!--end::Bullet-->
                  </a>
                  <!--end::Nav link-->
                </li>
                <!--end::Nav item-->
                <!--begin::Nav item-->
                <li class="nav-item p-0 ms-0 me-8" role="presentation">
                  <!--begin::Nav link-->
                  <a class="nav-link btn btn-color-muted px-0 show" data-bs-toggle="tab" href="#kt_table_widget_7_tab_content_3" aria-selected="false" role="tab" tabindex="-1">
                    <!--begin::Title-->
                    <span class="nav-text fw-semibold fs-4 mb-3">
                            Wednesday                        </span>
                    <!--end::Title-->

                    <!--begin::Bullet-->
                    <span class="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded"></span>
                    <!--end::Bullet-->
                  </a>
                  <!--end::Nav link-->
                </li>
                <!--end::Nav item-->
                <!--begin::Nav item-->
                <li class="nav-item p-0 ms-0 me-8" role="presentation">
                  <!--begin::Nav link-->
                  <a class="nav-link btn btn-color-muted px-0" data-bs-toggle="tab" href="#kt_table_widget_7_tab_content_4" aria-selected="false" role="tab" tabindex="-1">
                    <!--begin::Title-->
                    <span class="nav-text fw-semibold fs-4 mb-3">
                            Thursday                        </span>
                    <!--end::Title-->

                    <!--begin::Bullet-->
                    <span class="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded"></span>
                    <!--end::Bullet-->
                  </a>
                  <!--end::Nav link-->
                </li>
                <!--end::Nav item-->
                <!--begin::Nav item-->
                <li class="nav-item p-0 ms-0 me-8" role="presentation">
                  <!--begin::Nav link-->
                  <a class="nav-link btn btn-color-muted px-0" data-bs-toggle="tab" href="#kt_table_widget_7_tab_content_5" aria-selected="false" role="tab" tabindex="-1">
                    <!--begin::Title-->
                    <span class="nav-text fw-semibold fs-4 mb-3">
                            Friday                        </span>
                    <!--end::Title-->

                    <!--begin::Bullet-->
                    <span class="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded"></span>
                    <!--end::Bullet-->
                  </a>
                  <!--end::Nav link-->
                </li>
                <!--end::Nav item-->

              </ul>
              <!--end::Nav-->

              <!--begin::Toolbar-->
              <div class="card-toolbar">
                <!--begin::Daterangepicker(defined in src/js/layout/app.js)-->
                <div data-kt-daterangepicker="true" data-kt-daterangepicker-opens="left" class="btn btn-sm btn-light d-flex align-items-center px-4" data-kt-initialized="1">
                  <!--begin::Display range-->
                  <div class="text-gray-600 fw-bold">27 Feb 2023 - 28 Mar 2023</div>
                  <!--end::Display range-->

                  <i class="ki-duotone ki-calendar-8 fs-1 ms-2 me-0"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></i>
                </div>
                <!--end::Daterangepicker-->
              </div>
              <!--end::Toolbar-->
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div class="card-body">
              <!--begin::Tab Content (ishlamayabdi)-->
              <div class="tab-content mb-2">
                <!--begin::Tap pane-->
                <div class="tab-pane fade active show" id="kt_table_widget_7_tab_content_1" role="tabpanel">
                  <!--begin::Table container-->
                  <div class="table-responsive">
                    <!--begin::Table-->
                    <table class="table align-middle">
                      <!--begin::Table head-->
                      <thead>
                      <tr>
                        <th class="min-w-150px p-0"></th>
                        <th class="min-w-200px p-0"></th>
                        <th class="min-w-100px p-0"></th>
                        <th class="min-w-80px p-0"></th>
                      </tr>
                      </thead>
                      <!--end::Table head-->

                      <!--begin::Table body-->
                      <tbody>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          11:43-09:43am                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 1:
                          <span class="text-gray-800">French class</span>


                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Cabinet:
                          <span class="text-gray-800">5</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>

                      <tr>
                        <td class="bg-light rounded text-gray-600 fs-8 fw-bold px-3 py-2" colspan="4">
                          Break 205min                                            </td>
                      </tr>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          09:40-11:20am                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 2:
                          <span class="text-gray-800">Physics class</span>


                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Cabinet:
                          <span class="text-gray-800">13</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>

                      <tr>
                        <td class="bg-light rounded text-gray-600 fs-8 fw-bold px-3 py-2" colspan="4">
                          Break 25min                                            </td>
                      </tr>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          10:35-43:09am                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 3:
                          <span class="text-gray-800">Chemistry class</span>


                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Court:
                          <span class="text-gray-800">Main</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>

                      <tr>
                        <td class="bg-light rounded text-gray-600 fs-8 fw-bold px-3 py-2" colspan="4">
                          Break 15min                                            </td>
                      </tr>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          15:30-12:23pm                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 4:
                          <span class="text-gray-800">Biology class</span>

                          <!--begin::Icon-->
                          <span class="cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Held by Dr. Ana" data-bs-original-title="Held by Dr. Ana" data-kt-initialized="1">
                                                        <i class="ki-duotone ki-information fs-1 text-warning"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                                                    </span>
                          <!--end::Icon-->

                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Cabinet:
                          <span class="text-gray-800">23</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>
                      </tbody>
                      <!--end::Table body-->
                    </table>
                    <!--end::Table-->
                  </div>
                  <!--end::Table container-->
                </div>
                <!--end::Tap pane-->
                <!--begin::Tap pane-->
                <div class="tab-pane fade" id="kt_table_widget_7_tab_content_2" role="tabpanel">
                  <!--begin::Table container-->
                  <div class="table-responsive">
                    <!--begin::Table-->
                    <table class="table align-middle">
                      <!--begin::Table head-->
                      <thead>
                      <tr>
                        <th class="min-w-150px p-0"></th>
                        <th class="min-w-200px p-0"></th>
                        <th class="min-w-100px p-0"></th>
                        <th class="min-w-80px p-0"></th>
                      </tr>
                      </thead>
                      <!--end::Table head-->

                      <!--begin::Table body-->
                      <tbody>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          09:15-12:23am                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 1:
                          <span class="text-gray-800">Geography class</span>


                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Cabinet:
                          <span class="text-gray-800">45</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>

                      <tr>
                        <td class="bg-light rounded text-gray-600 fs-8 fw-bold px-3 py-2" colspan="4">
                          Break 20min                                            </td>
                      </tr>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          08:30-09:30am                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 2:
                          <span class="text-gray-800">English class</span>


                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Cabinet:
                          <span class="text-gray-800">9</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>

                      <tr>
                        <td class="bg-light rounded text-gray-600 fs-8 fw-bold px-3 py-2" colspan="4">
                          Break 20min                                            </td>
                      </tr>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          11:15-12:13am                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 3:
                          <span class="text-gray-800">Sports class</span>


                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Court:
                          <span class="text-gray-800">Main</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>

                      <tr>
                        <td class="bg-light rounded text-gray-600 fs-8 fw-bold px-3 py-2" colspan="4">
                          Break 25min                                            </td>
                      </tr>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          14:10-15:35pm                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 4:
                          <span class="text-gray-800">Picture class</span>

                          <!--begin::Icon-->
                          <span class="cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Held by Dr. Lebron" data-bs-original-title="Held by Dr. Lebron" data-kt-initialized="1">
                                                        <i class="ki-duotone ki-information fs-1 text-warning"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                                                    </span>
                          <!--end::Icon-->

                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Cabinet:
                          <span class="text-gray-800">12</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>
                      </tbody>
                      <!--end::Table body-->
                    </table>
                    <!--end::Table-->
                  </div>
                  <!--end::Table container-->
                </div>
                <!--end::Tap pane-->
                <!--begin::Tap pane-->
                <div class="tab-pane fade" id="kt_table_widget_7_tab_content_3" role="tabpanel">
                  <!--begin::Table container-->
                  <div class="table-responsive">
                    <!--begin::Table-->
                    <table class="table align-middle">
                      <!--begin::Table head-->
                      <thead>
                      <tr>
                        <th class="min-w-150px p-0"></th>
                        <th class="min-w-200px p-0"></th>
                        <th class="min-w-100px p-0"></th>
                        <th class="min-w-80px p-0"></th>
                      </tr>
                      </thead>
                      <!--end::Table head-->

                      <!--begin::Table body-->
                      <tbody>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          08:30-09:12am                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 1:
                          <span class="text-gray-800">Math class</span>


                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Cabinet:
                          <span class="text-gray-800">45</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>

                      <tr>
                        <td class="bg-light rounded text-gray-600 fs-8 fw-bold px-3 py-2" colspan="4">
                          Break 15min                                            </td>
                      </tr>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          09:30-10:50am                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 2:
                          <span class="text-gray-800">History class</span>


                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Cabinet:
                          <span class="text-gray-800">12</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>

                      <tr>
                        <td class="bg-light rounded text-gray-600 fs-8 fw-bold px-3 py-2" colspan="4">
                          Break 20min                                            </td>
                      </tr>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          10:35-11:20am                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 3:
                          <span class="text-gray-800">Sports class</span>


                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Court:
                          <span class="text-gray-800">Main</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>

                      <tr>
                        <td class="bg-light rounded text-gray-600 fs-8 fw-bold px-3 py-2" colspan="4">
                          Break 15min                                            </td>
                      </tr>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          12:40-13:25pm                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 4:
                          <span class="text-gray-800">Chemistry class</span>

                          <!--begin::Icon-->
                          <span class="cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Held by Dr. Natali" data-bs-original-title="Held by Dr. Natali" data-kt-initialized="1">
                                                        <i class="ki-duotone ki-information fs-1 text-warning"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                                                    </span>
                          <!--end::Icon-->

                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Cabinet:
                          <span class="text-gray-800">19</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>
                      </tbody>
                      <!--end::Table body-->
                    </table>
                    <!--end::Table-->
                  </div>
                  <!--end::Table container-->
                </div>
                <!--end::Tap pane-->
                <!--begin::Tap pane-->
                <div class="tab-pane fade" id="kt_table_widget_7_tab_content_4" role="tabpanel">
                  <!--begin::Table container-->
                  <div class="table-responsive">
                    <!--begin::Table-->
                    <table class="table align-middle">
                      <!--begin::Table head-->
                      <thead>
                      <tr>
                        <th class="min-w-150px p-0"></th>
                        <th class="min-w-200px p-0"></th>
                        <th class="min-w-100px p-0"></th>
                        <th class="min-w-80px p-0"></th>
                      </tr>
                      </thead>
                      <!--end::Table head-->

                      <!--begin::Table body-->
                      <tbody>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          11:25-14:13am                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 1:
                          <span class="text-gray-800">Geography class</span>


                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Cabinet:
                          <span class="text-gray-800">15</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>

                      <tr>
                        <td class="bg-light rounded text-gray-600 fs-8 fw-bold px-3 py-2" colspan="4">
                          Break 25min                                            </td>
                      </tr>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          08:30-09:30am                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 2:
                          <span class="text-gray-800">English class</span>


                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Cabinet:
                          <span class="text-gray-800">9</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>

                      <tr>
                        <td class="bg-light rounded text-gray-600 fs-8 fw-bold px-3 py-2" colspan="4">
                          Break 20min                                            </td>
                      </tr>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          11:15-12:13am                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 3:
                          <span class="text-gray-800">Sports class</span>


                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Court:
                          <span class="text-gray-800">Main</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>

                      <tr>
                        <td class="bg-light rounded text-gray-600 fs-8 fw-bold px-3 py-2" colspan="4">
                          Break 25min                                            </td>
                      </tr>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          14:10-15:35pm                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 4:
                          <span class="text-gray-800">Picture class</span>

                          <!--begin::Icon-->
                          <span class="cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Held by Dr. Kevin" data-bs-original-title="Held by Dr. Kevin" data-kt-initialized="1">
                                                        <i class="ki-duotone ki-information fs-1 text-warning"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                                                    </span>
                          <!--end::Icon-->

                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Cabinet:
                          <span class="text-gray-800">12</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>
                      </tbody>
                      <!--end::Table body-->
                    </table>
                    <!--end::Table-->
                  </div>
                  <!--end::Table container-->
                </div>
                <!--end::Tap pane-->
                <!--begin::Tap pane-->
                <div class="tab-pane fade" id="kt_table_widget_7_tab_content_5" role="tabpanel">
                  <!--begin::Table container-->
                  <div class="table-responsive">
                    <!--begin::Table-->
                    <table class="table align-middle">
                      <!--begin::Table head-->
                      <thead>
                      <tr>
                        <th class="min-w-150px p-0"></th>
                        <th class="min-w-200px p-0"></th>
                        <th class="min-w-100px p-0"></th>
                        <th class="min-w-80px p-0"></th>
                      </tr>
                      </thead>
                      <!--end::Table head-->

                      <!--begin::Table body-->
                      <tbody>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          11:43-09:43am                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 1:
                          <span class="text-gray-800">French class</span>


                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Cabinet:
                          <span class="text-gray-800">5</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>

                      <tr>
                        <td class="bg-light rounded text-gray-600 fs-8 fw-bold px-3 py-2" colspan="4">
                          Break 205min                                            </td>
                      </tr>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          09:40-11:20am                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 2:
                          <span class="text-gray-800">Physics class</span>


                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Cabinet:
                          <span class="text-gray-800">13</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>

                      <tr>
                        <td class="bg-light rounded text-gray-600 fs-8 fw-bold px-3 py-2" colspan="4">
                          Break 25min                                            </td>
                      </tr>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          10:35-43:09am                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 3:
                          <span class="text-gray-800">Chemistry class</span>


                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Court:
                          <span class="text-gray-800">Main</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>

                      <tr>
                        <td class="bg-light rounded text-gray-600 fs-8 fw-bold px-3 py-2" colspan="4">
                          Break 15min                                            </td>
                      </tr>

                      <tr>
                        <td class="fs-6 fw-bold text-gray-800">
                          15:30-12:23pm                                            </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          lesson 4:
                          <span class="text-gray-800">Biology class</span>

                          <!--begin::Icon-->
                          <span class="cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Held by Dr. Emma" data-bs-original-title="Held by Dr. Emma" data-kt-initialized="1">
                                                        <i class="ki-duotone ki-information fs-1 text-warning"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                                                    </span>
                          <!--end::Icon-->

                        </td>
                        <td class="fs-6 fw-bold text-gray-400">
                          Cabinet:
                          <span class="text-gray-800">23</span>

                        </td>
                        <td class="pe-0 text-end">
                          <button class="btn btn-sm btn-light">
                            Skip
                          </button>
                        </td>
                      </tr>
                      </tbody>
                      <!--end::Table body-->
                    </table>
                    <!--end::Table-->
                  </div>
                  <!--end::Table container-->
                </div>
                <!--end::Tap pane-->

              </div>
              <!--end::Tab Content-->

              <!--begin::Action-->
              <div class="float-end">
                <a href="#" class="btn btn-sm btn-light me-2" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">Add Lesson</a>

                <a href="#" class="btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app">Call Sick for Today</a>
              </div>
              <!--end::Action-->
            </div>
            <!--end: Card Body-->
          </div>
          <!--end::Table widget 8-->

        </div>
        <!--end::Col-->

        <!--begin::Col-->
        <div class="col-xl-4">
          <!--begin::Engage widget 5-->
          <div class="card bg-primary h-xl-100">
            <!--begin::Body-->
            <div class="card-body d-flex flex-column pt-13 pb-14">
              <!--begin::Heading-->
              <div class="m-0">
                <!--begin::Title-->
                <h1 class="fw-semibold text-white text-center lh-lg mb-9">
                  How are you feeling today?                <span class="fw-bolder"> Here we are to Help</span>
                </h1>
                <!--end::Title-->

                <!--begin::Illustration-->
                <div class="flex-grow-1 bgi-no-repeat bgi-size-contain bgi-position-x-center card-rounded-bottom h-200px mh-200px my-5 mb-lg-12" style="background-image:url('/metronic8/demo1/assets/media/svg/illustrations/easy/6.svg')">
                </div>
                <!--end::Illustration-->
              </div>
              <!--end::Heading-->

              <!--begin::Links-->
              <div class="text-center">
                <!--begin::Link-->
                <a class="btn btn-sm btn-success btn-color-white me-2" data-bs-target="#kt_modal_invite_friends" data-bs-toggle="modal">
                  Psychologist            </a>
                <!--end::Link-->

                <!--begin::Link-->
                <a class="btn btn-sm bg-white btn-color-white bg-opacity-20" href="/metronic8/demo1/../demo1/pages/careers/list.html">
                  Nurse            </a>
                <!--end::Link-->
              </div>
              <!--end::Links-->
            </div>
            <!--end::Body-->
          </div>
          <!--end::Engage widget 5-->

        </div>
        <!--end::Col-->
      </div>
    </v-app>

</template>
<script>

    export default {
        name: "parental-information",
    };
</script>
